import { render, staticRenderFns } from "./LoginNavButton.vue?vue&type=template&id=dcc15d28&scoped=true&lang=pug&"
import script from "./LoginNavButton.vue?vue&type=script&lang=js&"
export * from "./LoginNavButton.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dcc15d28",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CommonSpinner: require('/Users/virs/Chayou/chayou_com/components/common/Spinner.vue').default,UsersAvatar: require('/Users/virs/Chayou/chayou_com/components/users/Avatar.vue').default})

import axios from "axios";
import isEmpty from 'lodash/isEmpty';
import AuthHelper from "~/utils/request/auth";
import CryptoJS from 'crypto-js';
import RequestUrls from '~/utils/request/requestUrls';

const axiosApiInstance = axios.create();

/**
 * 接口请求超时时间设置
 */
const timeout = 1000 * 60;

/**
 * 默认提交的content-type
 */
const defaultContentType = "application/json";

/**
 * formdata
 */
const formDataContentType = "multipart/form-data";

/**
 * 跨域配置
 */
axiosApiInstance.defaults.withCredentials = false;


//- 计算签名
//- 返回 &sign=&time=UTC Timezone 如2021-10-20T04:36:34.162Z
//- sign是经过 SHA256 加密的字符串模板，拼接方式为 pathname \r\n method 大写 r\n\ time 动态加密
//- 将返回的数据存放在header中请求，如果错误将导致接口拒绝这次请求
const cs = function (p, method) {
  const m = method.toUpperCase();
  const t = new Date().toISOString();

  //- 拼写的字符串一定要正确，否则将导致签名失效
  const temp = `${p} \r\n ${m} \r\n ${t}`;
  const sign = CryptoJS.SHA256(temp).toString();

  return {
    sign,
    time: t
  }
}

//-Request interceptor for API calls
//-自动补全票据
axiosApiInstance.interceptors.request.use(
  async config => {

    //- 请求地址
    let url = new URL(config.url);

    const token = new AuthHelper().getLocalStoredToken();
    let headers = {
      "Content-Type": defaultContentType,
    }

    //- 补全token
    if (!isEmpty(token) && typeof token.access_token !== "undefined") {
      headers["token"] = `Bearer ${token.access_token}`;
    }
    //- 补全origin
    if (process.server) {
      headers["origin"] = url.origin;
    }
    config.headers = headers;

    //- 计算签名
    // const sign = cs(url.pathname, config.method)
    //- 避免重签
    // if (!url.searchParams.has("sign") && !url.searchParams.has("time")) {
    //   url.searchParams.append("sign", sign.sign);
    //   url.searchParams.append("time", sign.time);
    // }
    //- 重置
    config.url = url.toString();

    return config;
  },
  error => {
    Promise.reject(error)
  });


//-Response interceptor for API calls
//-自动刷新token
axiosApiInstance.interceptors.response.use((response) => {
  return response
}, async function (error) {
  const originalRequest = error.config;
  
  if (typeof error.response === "undefined") {
    //- 提示链接错误 注意showErrors({message: ''}) 传入object
    const message = error.message || "客户端未知的请求错误";
    Request.getInstance().showErrors({ message });
    return Promise.reject(error);
  }

  if (error.response.status === 401 && !originalRequest._retry) {
    originalRequest._retry = true;
    const token = await Request.getInstance().refreshaccess_token();
    if (!token) {
      await Request.getInstance().redirectLogin();
      return Promise.reject(Error("failed to refresh token."));
    }

    //-保存刷新后的toKen
    new AuthHelper().saveToken(token);
    axios.defaults.headers.common['token'] = 'Bearer ' + token.access_token;
    return axiosApiInstance(originalRequest);
  }

  //- 处理接口返回的错误提示
  if (error.response.status != 200) {
    Request.getInstance().showErrors(error.response.data || null);
  }

  return Promise.reject(error);
});


export default class Request {
  constructor() {
    this.instance = null;
  }

  /**
   * 获取静态实例
   * @returns instance
   */
  static getInstance() {
    if (!this.instance) {
      this.instance = new Request();
    }
    return this.instance;
  }

  /**
   * 跳转到登录页
   */
  async redirectLogin() {
    if (!process.client) {
      return;
    }

    const rs = await swal({
      title: '登录过期',
      text: '您的登录状态已经过期，请重新登录后继续',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: '好的，立即登录',
      cancelButtonText: '取消'
    });
    const callback = encodeURI(window.location.href);

    if (rs.isConfirmed) {
      window.location.href = `/users/login?callback=${callback}`;
    } else if (rs.dismiss === Swal.DismissReason.cancel) {
      console.log("canceled");
    }
  }

  /**
   * refresh access token
   * @param {*} opts 
   * @returns response
   */
  async refreshaccess_token() {
    const token = new AuthHelper().getLocalStoredToken();
    if (isEmpty(token)) {
      return null;
    }

    const refresh_token = `Bearer ${token.refresh_token}`;
    const resp = await this.postJson({
      url: RequestUrls.USER_REFRESH_TOKEN, data: {
        refresh_token
      }
    });
    const rs = resp.data;

    return rs.data || null;
  }

  /**
   * 
   * @param {*} opts 
   * @returns response
   */
  get(opts, cancelToken) {
    return axiosApiInstance.get(opts.url,
      {
        ...opts.data,
      }, {
      timeout,
      cancelToken: cancelToken === undefined ? null : cancelToken
    });
  }

  /**
   * post josn
   * @param {*} opts 
   * @returns response
   */
  postJson(options, cancelToken) {
    return axiosApiInstance.post(options.url,
      {
        ...options.data,
      }, {
      headers: {
        'Content-Type': defaultContentType
      },
      cancelToken: cancelToken === undefined ? null : cancelToken,
      timeout,
    });
  }

  /**
   * upload files
   * @param {*} opts 
   * @returns response
   */
  uploadFile(options, cancelToken) {
    return axiosApiInstance.post(options.url,
      {
        ...options.formData,
      }, {
      headers: {
        'Content-Type': formDataContentType
      },
      cancelToken: cancelToken === undefined ? null : cancelToken,
      timeout,
    });
  }

  /**
   * delete
   * @param {*} opts 
   * @returns response
   */
  delete(options, cancelToken) {
    return axiosApiInstance.delete(options.url,
      {
        ...options.data,
      }, {
      cancelToken: cancelToken === undefined ? null : cancelToken,
      timeout
    });
  }

  /**
   * pacth
   * @param {*} opts 
   * @returns response
   */
  pacth(options, cancelToken) {
    return axiosApiInstance.patch(options.url,
      {
        ...options.data
      },
      {
        headers: {
          'Content-Type': defaultContentType
        },
        cancelToken: cancelToken === undefined ? null : cancelToken,
        timeout
      }
    );
  }


  /**
   * 提示错误
   * data = {message: "错误提示", errors: {...服务端返回错误}} message优先级最高，其次显示 errors
   * @param {*} data 
   * void
   */
  showErrors(data) {

    //-处理和拼接错误字符串
    let message = data.message || "";
    if (isEmpty(message)) {
      message = "未知错误"
    }

    if (process.server) {
      return;
    }

    // new Toast().show({
    //   type: 'error',
    //   content: message
    // })
    swal({
      icon: "error",
      title: "接口发生错误",
      text: message,
      buttons: "好的",
    });
  }
}

import isEmpty from 'lodash/isEmpty';
import CryptoJS from 'crypto-js';

const encryptKey = "simpe_ase_encryt_key";

//- encrypt data
function encrypt(value) {
  return CryptoJS.AES.encrypt(value, encryptKey).toString();
}

//- decrypt
function decrypt(value) {
  var bytes = CryptoJS.AES.decrypt(value, encryptKey);
  return bytes.toString(CryptoJS.enc.Utf8);
}

//- client side storage
export default class Storage {
  constructor() {
    if (!process.client) {
     return;
    }

    this.store = localStorage || window.localStorage;
  }

  //- set
  set(key, val) {
    if (!process.client) {
      return;
    }

    try {
      this.store.setItem(key, encrypt(val.toString()));
    } catch (error) {
      throw error;
    }
  }

  //- get
  get(key) {
    if (!process.client) {
      return "";
    }

    try {
      let val = window.localStorage.getItem(key);
      if (isEmpty(val)) {
        return "";
      }
      return decrypt(val);
    } catch (error) {
      throw error;
    }
  }

  del(key) {
    this.store.removeItem(key);
  }

  //- clean all
  clean() {
    this.store.clear();
  }
}

import isEmpty from 'lodash/isEmpty';
import Storage from "~/utils/storage";

//-用于存储accessTokenStorageKey
const accessTokenStorageKey = "uk";

export default class AuthHelper {

  constructor(){
    this.store = new Storage();
  }

  //- saveToken
  saveToken(token) {
    if (!process.client) {
      return false;
    }

    this.store.set(accessTokenStorageKey, JSON.stringify(token));
    return true;
  }

  //- get local token
  getLocalStoredToken() {
    if (!process.client) {
      return null;
    }

    const tokenString = this.store.get(accessTokenStorageKey);
    return isEmpty(tokenString) ? null : JSON.parse(tokenString);
  }

  //- 清空 
  clear() {
    if (!process.client) {
      return null;
    }
    this.store.del(accessTokenStorageKey);
  }
}

import usersApi from "~/api/users";
import AuthHelper from "~/utils/request/auth";

const helper = new AuthHelper();

export const state = () => ({
  /// 登录用户
  user: null,
  /// 是否正在加载用户信息
  loadingUserInfo: false,
})

export const mutations = {
  //- update bootstrap information
  updateUser(state, user) {
    state.user = user;
  },
  updateloadingUserInfo(state, loading) {
    state.loadingUserInfo = loading;
  },
}

export const actions = {
  //- 使用短信验证码登录
  async codeLogin(context, data) {
    const rs = await usersApi.codeLogin(data);
    if (!rs) {
      return;
    }

    // 保存票据
    helper.saveToken(rs.data);
    return await context.dispatch("restoreLoginState");
  },
  //- 使用用户名密码登录
  //- 如果登录失败，则返回false
  async passwordLogin(context, data) {
    const rs = await usersApi.passworLogin(data);
    if (!rs) {
      return;
    }

    // 保存票据
    helper.saveToken(rs.data);
    return await context.dispatch("restoreLoginState");
  },
  //- 恢复登录状态
  async restoreLoginState(context) {
    const token = helper.getLocalStoredToken();
    if (token == null) {
      return;
    }


    //-设置正在恢复用户状态
    context.commit("updateloadingUserInfo", true);
    const rs = await usersApi.info();
    context.commit("updateloadingUserInfo", false);
    if (rs) {
      context.commit("updateUser", rs.data);
      return true;
    }
    return;

  },
  //- 退出
  logout(context) {
    helper.clear();
    context.commit("updateUser", null);
  }


}

export const namespaced = true;

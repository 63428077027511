import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _7b6a8f24 = () => interopDefault(import('../pages/download.vue' /* webpackChunkName: "pages/download" */))
const _1ae41a10 = () => interopDefault(import('../pages/events/index.vue' /* webpackChunkName: "pages/events/index" */))
const _ca295e72 = () => interopDefault(import('../pages/search/index.vue' /* webpackChunkName: "pages/search/index" */))
const _2477a4fb = () => interopDefault(import('../pages/trade/index.vue' /* webpackChunkName: "pages/trade/index" */))
const _af718752 = () => interopDefault(import('../pages/warehouse.vue' /* webpackChunkName: "pages/warehouse" */))
const _7efa349a = () => interopDefault(import('../pages/docs/policy.vue' /* webpackChunkName: "pages/docs/policy" */))
const _3b73c650 = () => interopDefault(import('../pages/docs/privacy.vue' /* webpackChunkName: "pages/docs/privacy" */))
const _603a899d = () => interopDefault(import('../pages/trade/trends.vue' /* webpackChunkName: "pages/trade/trends" */))
const _741d67b6 = () => interopDefault(import('../pages/users/login.vue' /* webpackChunkName: "pages/users/login" */))
const _2f4a2689 = () => interopDefault(import('../pages/events/list/_status/_page.vue' /* webpackChunkName: "pages/events/list/_status/_page" */))
const _59f53367 = () => interopDefault(import('../pages/trade/recent/_action/_page.vue' /* webpackChunkName: "pages/trade/recent/_action/_page" */))
const _2d997b29 = () => interopDefault(import('../pages/events/_id.html.vue' /* webpackChunkName: "pages/events/_id.html" */))
const _d0083374 = () => interopDefault(import('../pages/trade/_id.html.vue' /* webpackChunkName: "pages/trade/_id.html" */))
const _3fc1ae34 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/download",
    component: _7b6a8f24,
    name: "download"
  }, {
    path: "/events",
    component: _1ae41a10,
    name: "events"
  }, {
    path: "/search",
    component: _ca295e72,
    name: "search"
  }, {
    path: "/trade",
    component: _2477a4fb,
    name: "trade"
  }, {
    path: "/warehouse",
    component: _af718752,
    name: "warehouse"
  }, {
    path: "/docs/policy",
    component: _7efa349a,
    name: "docs-policy"
  }, {
    path: "/docs/privacy",
    component: _3b73c650,
    name: "docs-privacy"
  }, {
    path: "/trade/trends",
    component: _603a899d,
    name: "trade-trends"
  }, {
    path: "/users/login",
    component: _741d67b6,
    name: "users-login"
  }, {
    path: "/events/list/:status?/:page?",
    component: _2f4a2689,
    name: "events-list-status-page"
  }, {
    path: "/trade/recent/:action?/:page?",
    component: _59f53367,
    name: "trade-recent-action-page"
  }, {
    path: "/events/:id.html",
    component: _2d997b29,
    name: "events-id.html"
  }, {
    path: "/trade/:id.html",
    component: _d0083374,
    name: "trade-id.html"
  }, {
    path: "/",
    component: _3fc1ae34,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}

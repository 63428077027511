import Request from "~/utils/request";
import RequestUrls from "~/utils/request/requestUrls";

const req = new Request();

export default {
  async bootstrap() {
    const rs = await req.get({
      url: RequestUrls.TRADE_BOOTSTRAP
    });
    return rs.data;
  },
  /**
   * 经纪人列表
   * "page": "1",
   * "listRows": "20"
   */
  async agents(data) {
    const rs = await req.postJson({
      url: RequestUrls.TRADE_AGENTS,
      data
    });
    return rs.data;
  },
  /// 热门数据接口
  async hotData() {
    const rs = await req.get({
      url: RequestUrls.TRADE_HOT_DATA
    });
    return rs.data;
  },
  /// 买卖产品列表接口
  async list(data) {
    const rs = await req.postJson({
      url: RequestUrls.TRADE_LIST,
      data
    });
    return rs.data;
  },
  /// 买卖产品详情
  async detail(id) {
    const rs = await req.get({
      url: `${RequestUrls.TRADE_DETAIL}/${id}`,

    });
    return rs.data;
  },
}
import bootstrapApi from "~/api/bootstrap";

export const state = () => ({
  bootstrap: null,
  
})

export const mutations = {
  //- update bootstrap information
  updateBootstrap(state, bootstrap) {
    state.bootstrap = bootstrap;
  },
}

export const actions = {
  //- 启动配置
  async bootstrap(context) {
    const rs = await bootstrapApi.bootstrap();
    if (rs) {
      context.commit("updateBootstrap", rs.data);
      return true;
    }
    return;
  },

}

export const namespaced = true;

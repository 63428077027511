//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from "vuex";

export default {
  props: {
    width: String,
  },
  mounted() {
    this.$nextTick(() => {});
  },
  computed: {
    ...mapState("users", { user: (state) => state.user }),
    // 计算用户真实头像
    avatarUrl() {
      const { user } = this;

      const condition =
        user == null || this.$_.isEmpty(user.attributes.logo_file_medium_src);
      if (condition) {
        return "/images/noavatar.png";
      }

      return user.attributes.logo_file_medium_src;
    },
  },
  methods: {
    async handleLogout(){
      await this.$store.dispatch('users/logout');
      window.location.reload();
    }
  },
};

import Common from '~/utils/common';

const Host = Common.isProduction ? 'https://api.chayou.com' : 'http://localhost:3000/api';

export default {
  /// app更新历史json
  UPDATE_JSON: 'https://app.chayou.com/updates.json',
  /// 下载APK的Url
  APK_URL: 'https://app.chayou.com/app-chayou-release.apk',

  /// Bootstrap
  BOOTSTRAP: `${Host}/v1/bootstrap`,

  /// Bootstrap
  TRADE_BOOTSTRAP: `${Host}/v1/exchange/bootstrap`,
  /// 经纪人列表
  TRADE_AGENTS: `${Host}/v1/exchange/agents`,
  /// 热门数据
  TRADE_HOT_DATA: `${Host}/v1/exchange/hot-data`,
  /// 买卖产品列表接口
  TRADE_LIST: `${Host}/v1/exchange/list`,
  /// 买卖产品详情接口
  TRADE_DETAIL: `${Host}/v1/exchange/detail`,


   /// users
   /// 用户名密码登录
   USERS_PASSWORD_LOGIN: `${Host}/v1/login/password-login`,
   /// 当前登录的用户信息
   USERS_INFO: `${Host}/v1/user/user-info`,
   /// 刷新Token
   USER_REFRESH_TOKEN: `${Host}/v1/login/refresh-token`,
   /// 发送短信验证码
   SEND_CODE: `${Host}/v1/login/send-code`,
   /// 短信验证码登录
   CODE_LOGIN: `${Host}/v1/login/code-login`,

   /// 评测首页
   TRY_EVENT_INDEX: `${Host}/v1/try-event/index`,
   /// 评测活动列表
   TRY_EVENT_LIST: `${Host}/v1/try-event/center/list`,
   /// 评测详情接口
   TRY_EVENT_DETAIL: `${Host}/v1/try-event/center/detail`,

  
}
/// 百度统计
var _hmt = _hmt || [];
(function () {
  var hm = document.createElement("script");
  hm.src = "https://hm.baidu.com/hm.js?36751736231c2c93e9caecb542d4131e";
  var s = document.getElementsByTagName("script")[0];
  s.parentNode.insertBefore(hm, s);
})();

(function () {
  var bp = document.createElement("script");
  bp.src = 'https://zz.bdstatic.com/linksubmit/push.js';
  var s = document.getElementsByTagName("script")[0];
  s.parentNode.insertBefore(bp, s);
})();
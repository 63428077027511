export const Logo = () => import('../../components/Logo.vue' /* webpackChunkName: "components/logo" */).then(c => wrapFunctional(c.default || c))
export const AppEmbed = () => import('../../components/app/Embed.vue' /* webpackChunkName: "components/app-embed" */).then(c => wrapFunctional(c.default || c))
export const AppModal = () => import('../../components/app/Modal.vue' /* webpackChunkName: "components/app-modal" */).then(c => wrapFunctional(c.default || c))
export const AppPermissions = () => import('../../components/app/Permissions.vue' /* webpackChunkName: "components/app-permissions" */).then(c => wrapFunctional(c.default || c))
export const CommonEmpty = () => import('../../components/common/Empty.vue' /* webpackChunkName: "components/common-empty" */).then(c => wrapFunctional(c.default || c))
export const CommonFooter = () => import('../../components/common/Footer.vue' /* webpackChunkName: "components/common-footer" */).then(c => wrapFunctional(c.default || c))
export const CommonIcon = () => import('../../components/common/Icon.vue' /* webpackChunkName: "components/common-icon" */).then(c => wrapFunctional(c.default || c))
export const CommonNav = () => import('../../components/common/Nav.vue' /* webpackChunkName: "components/common-nav" */).then(c => wrapFunctional(c.default || c))
export const CommonPagination = () => import('../../components/common/Pagination.vue' /* webpackChunkName: "components/common-pagination" */).then(c => wrapFunctional(c.default || c))
export const CommonSearchField = () => import('../../components/common/SearchField.vue' /* webpackChunkName: "components/common-search-field" */).then(c => wrapFunctional(c.default || c))
export const CommonSecondaryNav = () => import('../../components/common/SecondaryNav.vue' /* webpackChunkName: "components/common-secondary-nav" */).then(c => wrapFunctional(c.default || c))
export const CommonSendMessage = () => import('../../components/common/SendMessage.vue' /* webpackChunkName: "components/common-send-message" */).then(c => wrapFunctional(c.default || c))
export const CommonSpinner = () => import('../../components/common/Spinner.vue' /* webpackChunkName: "components/common-spinner" */).then(c => wrapFunctional(c.default || c))
export const EventsBanner = () => import('../../components/events/Banner.vue' /* webpackChunkName: "components/events-banner" */).then(c => wrapFunctional(c.default || c))
export const EventsCounter = () => import('../../components/events/Counter.vue' /* webpackChunkName: "components/events-counter" */).then(c => wrapFunctional(c.default || c))
export const EventsDetailHeader = () => import('../../components/events/DetailHeader.vue' /* webpackChunkName: "components/events-detail-header" */).then(c => wrapFunctional(c.default || c))
export const EventsListItem = () => import('../../components/events/ListItem.vue' /* webpackChunkName: "components/events-list-item" */).then(c => wrapFunctional(c.default || c))
export const EventsRecentBanner = () => import('../../components/events/RecentBanner.vue' /* webpackChunkName: "components/events-recent-banner" */).then(c => wrapFunctional(c.default || c))
export const EventsRecentRecommends = () => import('../../components/events/RecentRecommends.vue' /* webpackChunkName: "components/events-recent-recommends" */).then(c => wrapFunctional(c.default || c))
export const EventsSecondaryNav = () => import('../../components/events/SecondaryNav.vue' /* webpackChunkName: "components/events-secondary-nav" */).then(c => wrapFunctional(c.default || c))
export const UsersAvatar = () => import('../../components/users/Avatar.vue' /* webpackChunkName: "components/users-avatar" */).then(c => wrapFunctional(c.default || c))
export const UsersLoginForm = () => import('../../components/users/LoginForm.vue' /* webpackChunkName: "components/users-login-form" */).then(c => wrapFunctional(c.default || c))
export const UsersLoginNavButton = () => import('../../components/users/LoginNavButton.vue' /* webpackChunkName: "components/users-login-nav-button" */).then(c => wrapFunctional(c.default || c))
export const TradeAgentItem = () => import('../../components/trade/AgentItem.vue' /* webpackChunkName: "components/trade-agent-item" */).then(c => wrapFunctional(c.default || c))
export const TradeHomeRecommeds = () => import('../../components/trade/HomeRecommeds.vue' /* webpackChunkName: "components/trade-home-recommeds" */).then(c => wrapFunctional(c.default || c))
export const TradeListItem = () => import('../../components/trade/ListItem.vue' /* webpackChunkName: "components/trade-list-item" */).then(c => wrapFunctional(c.default || c))
export const TradeProductCarousel = () => import('../../components/trade/ProductCarousel.vue' /* webpackChunkName: "components/trade-product-carousel" */).then(c => wrapFunctional(c.default || c))
export const TradeRecommendList = () => import('../../components/trade/RecommendList.vue' /* webpackChunkName: "components/trade-recommend-list" */).then(c => wrapFunctional(c.default || c))
export const TradeSecondaryNav = () => import('../../components/trade/SecondaryNav.vue' /* webpackChunkName: "components/trade-secondary-nav" */).then(c => wrapFunctional(c.default || c))
export const TradeSimpleSearchForm = () => import('../../components/trade/SimpleSearchForm.vue' /* webpackChunkName: "components/trade-simple-search-form" */).then(c => wrapFunctional(c.default || c))
export const DownloadFeature = () => import('../../components/download/Feature.vue' /* webpackChunkName: "components/download-feature" */).then(c => wrapFunctional(c.default || c))
export const DownloadFeatureGrids = () => import('../../components/download/FeatureGrids.vue' /* webpackChunkName: "components/download-feature-grids" */).then(c => wrapFunctional(c.default || c))
export const DownloadFeatureMoments = () => import('../../components/download/FeatureMoments.vue' /* webpackChunkName: "components/download-feature-moments" */).then(c => wrapFunctional(c.default || c))
export const DownloadHero = () => import('../../components/download/Hero.vue' /* webpackChunkName: "components/download-hero" */).then(c => wrapFunctional(c.default || c))
export const DownloadScan = () => import('../../components/download/Scan.vue' /* webpackChunkName: "components/download-scan" */).then(c => wrapFunctional(c.default || c))
export const HomeFavEntries = () => import('../../components/home/FavEntries.vue' /* webpackChunkName: "components/home-fav-entries" */).then(c => wrapFunctional(c.default || c))
export const HomeSearch = () => import('../../components/home/Search.vue' /* webpackChunkName: "components/home-search" */).then(c => wrapFunctional(c.default || c))
export const WarehouseHero = () => import('../../components/warehouse/Hero.vue' /* webpackChunkName: "components/warehouse-hero" */).then(c => wrapFunctional(c.default || c))
export const EventsPartsApplyAlert = () => import('../../components/events/parts/ApplyAlert.vue' /* webpackChunkName: "components/events-parts-apply-alert" */).then(c => wrapFunctional(c.default || c))
export const EventsPartsPickedUsers = () => import('../../components/events/parts/PickedUsers.vue' /* webpackChunkName: "components/events-parts-picked-users" */).then(c => wrapFunctional(c.default || c))
export const TradeLandingIndexes = () => import('../../components/trade/landing/Indexes.vue' /* webpackChunkName: "components/trade-landing-indexes" */).then(c => wrapFunctional(c.default || c))
export const TradeLandingMoreIndexes = () => import('../../components/trade/landing/MoreIndexes.vue' /* webpackChunkName: "components/trade-landing-more-indexes" */).then(c => wrapFunctional(c.default || c))
export const TradeLandingRecommendAgents = () => import('../../components/trade/landing/RecommendAgents.vue' /* webpackChunkName: "components/trade-landing-recommend-agents" */).then(c => wrapFunctional(c.default || c))
export const TradeLandingStorehouse = () => import('../../components/trade/landing/Storehouse.vue' /* webpackChunkName: "components/trade-landing-storehouse" */).then(c => wrapFunctional(c.default || c))
export const TradeTrendsHotBrands = () => import('../../components/trade/trends/HotBrands.vue' /* webpackChunkName: "components/trade-trends-hot-brands" */).then(c => wrapFunctional(c.default || c))
export const TradeTrendsHotYears = () => import('../../components/trade/trends/HotYears.vue' /* webpackChunkName: "components/trade-trends-hot-years" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}

import { isEmpty } from "lodash";
import tradeApi from "~/api/trade";
import Storage from "~/utils/storage";

export const state = () => ({
  /// 启动信息
  bootstrap: null,

})

export const mutations = {
  //- update bootstrap information
  updateBootstrap(state, bootstrap) {
    state.bootstrap = bootstrap;
  },
}

export const actions = {
  //- 启动配置
  async bootstrap(context) {
    const rs = await tradeApi.bootstrap();
    if (rs) {
      context.commit("updateBootstrap", rs.data);
      return true;
    }
    return;
  },

}

export const namespaced = true;

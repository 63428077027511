//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    showText: Boolean,
  },
};

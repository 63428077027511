//
//
//
//
//
//
//

export default {
  mounted(){
    this.$nextTick(async ()=> {
      await this.$store.dispatch('bootstrap/bootstrap');
      await this.$store.dispatch('users/restoreLoginState');
    });
  }
}

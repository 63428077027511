//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from "vuex";

export default {
  computed: {
    ...mapState("users", {
      user: (state) => state.user,
      loadingUserInfo: (state) => state.loadingUserInfo,
    }),
    /**
     * 撮合交易是否已经完成初始化
     */
    hasLogin() {
      const { user } = this;
      return user != null;
    },
  },
  methods: {
    handleClickLoginButton() {
      const currentUrl = window.location.href;
      this.$router.push(`/users/login?callback=${encodeURI(currentUrl)}`);
    },
  },
};

import Request from "~/utils/request";
import RequestUrls from "~/utils/request/requestUrls";

const req = new Request();

export default {
  async bootstrap() {
    const rs = await req.get({
      url: RequestUrls.BOOTSTRAP
    });
    return rs.data;
  },

}
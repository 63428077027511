export default {
  /**
   * 是否是生产模式
   * @returns bool
   */
   get isProduction() {
    return process.env.NODE_ENV === 'production';
  },

  /**
   * 是否是客户端
   */
  get isClient() {
    return !process.server;
  },
  
  /// 手机号是否可用
  isPoneAvailable(pone) {
    const myreg = /^[1][3,4,5,7,8][0-9]{9}$/;
    if (!myreg.test(pone)) {
      return false;
    } else {
      return true;
    }
  },

}
//
//
//
//
//
//

export default {
  props: {
    sm: Boolean,
    color: String
  },
  computed: {
    classes() {
      const { sm, color } = this;
      const textColor = `text-${color || 'white'}`;
      return sm ? `${textColor} spinner-border-sm` : `${textColor} spinner-border`;
    },
  },
};

//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: ["error"],
  head: {
    title: "发生错误",
    hid: 'error',
  }
};

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data() {
    return {
      navs: [
        { name: "首页", to: "/" },
        { name: "交易厅", to: "/trade" },
        { name: "试用活动", to: "/events" },
        { name: "茶友网APP", to: "/download" },
        { name: "自然仓", to: "/warehouse" },
      ],
    };
  },
};

import Request from "~/utils/request";
import RequestUrls from "~/utils/request/requestUrls";

const req = new Request();

export default {
  //- 使用手机号和密码进行登录
  async passworLogin(data) {
    const rs = await req.postJson({
      url: RequestUrls.USERS_PASSWORD_LOGIN,
      data
    });
    return rs.data;
  },
  //- 当前登录的用户信息
  async info() {
    const rs = await req.get({
      url: RequestUrls.USERS_INFO
    });
    return rs.data;
  },
  //- 发送短信验证码
  async sendCode(data) {
    const rs = await req.postJson({
      url: RequestUrls.SEND_CODE,
      data
    });
    return rs.data;
  },
  //- 发送短信验证码
  async codeLogin(data) {
    const rs = await req.postJson({
      url: RequestUrls.CODE_LOGIN,
      data
    });
    return rs.data;
  },
}